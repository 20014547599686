<template lang="pug">
aside.newsletter-popup
  div(v-if='subscribeStatus === "success"')
    p Votre inscription à la newsletter à été prise en compte !
  div(v-else-if='subscribeStatus === "alreadyExists"')
    p.success-msg Vous êtes déjà inscrit, merci de votre intérêt
  div(v-else)
    p J’ai envie d’être informé des nouveaux programmes mis en ligne et de lire les articles parus sur BLAST
    form(@submit.prevent='processSubscription')
      input.newsletter-input(type='email', placeholder='Adresse email', v-model='userEmail')
      button.newsletter-button(type='submit', aria-label='Souscrire')
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 12L10 8L6 4" stroke="#001A49" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    p.fail-msg(v-if='subscribeStatus === "fail"') Une erreur s'est produite, veuillez réessayer.
  button.close(aria-label='Close', @click.prevent='$emit("close:banner")')
    span(aria-hidden='true') &times;
</template>

<script>
import { subscribeToMailchimp } from '@/services/mailchimpService'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      subscribeStatus: null,
      userEmail: ''
    }
  },
  methods: {
    updateSubscribeStatus($event) {
      this.subscribeStatus = $event
    },
    async processSubscription() {
      await subscribeToMailchimp(this.userEmail, 'subscribed')
        .then((res) => {
          if (res.status === 400 && res.title === 'Member Exists') {
            this.subscribeStatus = 'alreadyExists'
          } else {
            this.subscribeStatus = 'success'
            Cookies.set('disableNewsletterPopup', true, { expires: 7 })
          }
        })
        .catch(() => (this.subscribeStatus = 'fail'))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/vars.scss';
.newsletter-popup {
  position: fixed;
  z-index: 20;
  bottom: 1vmin;
  left: 1vmin;
  max-width: 600px;
  min-width: 300px;
  padding: 2rem;
  border-radius: 10px;
  background-color: $darkBlue;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  border: 2px solid $black;
  align-items: flex-start;

  color: $white;

  .close {
    color: $white;
    background-color: transparent;
    font-size: 20px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    margin-left: 2rem;

    outline: none;
    border: none;
  }

  .newsletter-input {
    background: transparent;
    border: none;
    border-bottom: 1px solid $white;
    color: $white;
    padding: 1rem 1rem 1rem 0;
    width: 150px;

    &:focus {
      outline: none;
    }

    @include medium-screen {
      flex-direction: row;
      width: auto;
    }
  }
  .newsletter-button {
    background: $orange;
    border: none;
    border-radius: 5px 5px 0 0;
    color: $darkBlue;
    padding: 1rem 0 1rem 0;
    width: 40px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Hind Vadodara', sans-serif;

    &:focus {
      outline: none;
    }

    @include medium-screen {
      flex-direction: row;
    }
  }
}
</style>
